import React from 'react';

const Services: React.FC = () => {
  return (
    <div className="page-services-container container">
      SERVIÇOS
    </div>
  );
}

export default Services;
